import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isStartClass:0, //状态0未开始1进行中2已结束3取消
    boardFileId:'',
    classInfo:{},
    localStream:null
  },
  getters: {
  },
  mutations: {
    changeIsStartClass(state,payload){
      state.isStartClass = payload
    },
    changeBoardFileId(state,payload){
      state.boardFileId = payload
    },
    changeClassInfo(state,payload){
      state.classInfo = payload
    },
    changeLocalStream(state,payload){
      state.localStream = payload
    },

  },
  actions: {
  },
  modules: {
  },
});
